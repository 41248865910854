import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import ApolloProvider from 'react-apollo/ApolloProvider';
import ScrollMemory from 'react-router-scroll-memory';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/browser';

import App from './App';
import { createGalleryClient } from './network/galleryClient';
import {
  galleryApiUrl,
  getAuthToken,
  apolloInitialState,
  sentry
} from './clientConfig';

Sentry.init(sentry);

const apolloClient = createGalleryClient({
  galleryApiUrl,
  authLinkOptions: {
    getAuthToken
  },
  staticContext: {
    locale: (window as any).__LOCALE__
  },
  initialState: apolloInitialState
});

const clientApp = (
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ScrollMemory />
        <App />
      </QueryParamProvider>
    </BrowserRouter>
  </ApolloProvider>
);

loadableReady(() => {
  ReactDOM.hydrate(clientApp, document.getElementById('root') as HTMLElement);
});
