type BreakpointSpec = {
  readonly [breakpoint: string]: number;
};

type breakpoint = keyof typeof breakpoints;

export const breakpoints: BreakpointSpec = Object.freeze({
  smallMobile: 320,
  mobile: 600,
  smallTablet: 660,
  tablet: 768,
  largeTablet: 880,
  smallDesktop: 1024,
  mediumDesktop: 1200,
  largeDesktop: 1440
});

const toEM = (pixels: number) => pixels / 16;

export const above = (bp: breakpoint, modifier: number = 0) =>
  `@media (min-width: ${toEM(breakpoints[bp] + modifier)}em)`;
export const below = (bp: breakpoint, modifier: number = 0) =>
  `@media (max-width: ${toEM(breakpoints[bp] + modifier)}em)`;
export const exactly = (bp: breakpoint, modifier: number = 0) =>
  `@media (width: ${toEM(breakpoints[bp] + modifier)}em)`;
export const between = (bp1: breakpoint, bp2: breakpoint) =>
  `@media (min-width: ${toEM(breakpoints[bp1])}em) and (max-width: ${toEM(
    breakpoints[bp2]
  )}em`;
