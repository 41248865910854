import gql from 'graphql-tag';
import { Banner, App } from '../types';

export type LayoutItem = {
  id: string;
  type: string;
  title?: string;
  background?: { uri: string };
  banners?: Banner[];
  apps?: App[];
};

export type AppList = LayoutItem & {
  apps: App[];
  link: {
    resourceId: string;
    type: string;
  };
  isPremium: boolean;
};

export type BannerList = LayoutItem & {
  banners: Banner[];
};

export type GetPageLayoutResponse = {
  pageLayout: LayoutItem[];
};

export type GetPageLayoutVariables = {
  type: string;
};

export type GetEEAUserVariables = {
  clientIpAddress?: string;
  isEEAUser?: boolean;
};

export type GetEEAUserResponse = {
  isEEAUser?: boolean;
};

export const getEEAUser = gql`
  query isEEAUser($clientIpAddress: String) {
    isEEAUser(clientIpAddress: $clientIpAddress)
  }
`;

export const getPageLayout = gql`
  query pageLayout($type: String!, $id: ID, $clientIpAddress: String) {
    pageLayout(type: $type, id: $id, clientIpAddress: $clientIpAddress) {
      id
      type

      ... on BannerList {
        background {
          uri
        }
        banners {
          title
          text
          background {
            uri
          }
          options {
            hideScreenshot
          }
          button {
            text
            link {
              resourceId
              type
            }
          }
          isPremium
        }
      }

      ... on AppList {
        title
        link {
          resourceId
          type
        }
        apps(limit: 5) {
          id
          previewImage(scale: 1.0) {
            uri
          }
          icon(width: 120, height: 120) {
            uri
          }
          description
          developer {
            name
          }
          name
          type
          isPremium
        }
        isPremium
      }
    }
  }
`;
