export const colors = {
  white: '#ffffff',
  black: '#000000',
  irisBlue: '#00b0b9',
  tangaroa: '#002a3a',
  submarine: '#939c9e',
  ginFizz: '#f6ead0',
  creamCan: '#f4c04d',
  whiteSmoke: '#f8f8f8',
  ivory: '#f8f8f7',
  whisper: '#e6e6e6',
  lightWhisper: '#ececec',
  linkWater: '#cfd3d6',
  blueLagoon: '#056b84',
  sherpaBlue: '#02394d',
  silver: '#c4c4c4',
  gainsboro: '#d8d8d8',
  pattensBlue: '#e1e4e6',
  facebookBlue: '#3b4998',
  twitterBlue: '#1da1f2',
  premiumYellow: '#b7832f'
};
