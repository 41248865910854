import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

import MobileModal from './MobileModal';
import Popup from './Popup';
import { NoStyleLink } from './basic-components';

import { colors } from '../style-constants';
import { below, above } from '../media-query-utils';

import { useI18n, useMatchMedia } from '../hooks';
import { normalizedAllLocalesMap, flagMap } from '../sharedConfig';
import { PassedProps } from './LocaleRoute';

const Regions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  ${below('mobile')} {
    padding: 32px;
  }
`;

const RegionLink = styled(NoStyleLink)`
  display: flex;
  height: 15px;

  align-items: center;

  & + & {
    margin-top: 12px;
  }

  ${below('mobile')} {
    height: 22px;

    & + & {
      margin-top: 32px;
    }
  }
`;

const LanguageName = styled.span`
  color: ${colors.irisBlue};
  font-size: 14px;
  font-weight: 600;

  user-select: none;

  ${below('mobile')} {
    color: ${colors.black};
    font-size: 18px;
  }
`;

const Flag = styled.img`
  margin-right: 20px;
  width: 20px;

  ${below('mobile')} {
    width: 28px;
    margin-right: 16px;
  }
`;

const PickerTarget = styled.div`
  display: flex;
  height: 15px;

  cursor: pointer;

  ${below('mobile')} {
    display: none;
  }
`;

const RegionPopup = styled(Popup)`
  align-self: flex-start;
  flex-shrink: 0;

  ${above('mobile')} {
    order: 2;
    // This is to force the popup up 10px
    padding-top: 10px;
    margin-top: -10px;
  }
`;

const MobilePickerTarget = styled.div`
  display: flex;
  height: 15px;
  margin-bottom: 20px;

  & > ${LanguageName} {
    font-size: 14px;
    color: ${colors.submarine};
  }

  & > ${Flag} {
    width: 20px;
    margin-right: 10px;
  }

  ${above('mobile')} {
    display: none;
  }
`;

const RegionPicker: React.FC<PassedProps> = ({ locale = 'en-us' }) => {
  const { t } = useI18n();
  const [active, setActive] = useState(false);
  const toggleMobileModalActive = useCallback(() => setActive(!active), [
    active
  ]);

  const [isMobile] = useMatchMedia(`(max-width: 600px)`);

  const regions = (
    <Regions>
      {Array.from(normalizedAllLocalesMap)
        .sort(([, value1], [, value2]) => (value1 < value2 ? -1 : 1))
        .map(([key, value]) => (
          <RegionLink external to={`/${key}`} key={key}>
            <Flag src={flagMap.get(key)} />
            <LanguageName>{value}</LanguageName>
          </RegionLink>
        ))}
    </Regions>
  );

  return isMobile ? (
    <React.Fragment>
      <MobilePickerTarget onClick={toggleMobileModalActive}>
        <Flag src={flagMap.get(locale)} />
        <LanguageName>{normalizedAllLocalesMap.get(locale)}</LanguageName>
      </MobilePickerTarget>
      {active && (
        <MobileModal
          title={t`Choose Language`}
          active={active}
          toggle={toggleMobileModalActive}
        >
          {regions}
        </MobileModal>
      )}
    </React.Fragment>
  ) : (
    <RegionPopup
      target={
        <PickerTarget>
          <Flag src={flagMap.get(locale)} />
          <LanguageName>{normalizedAllLocalesMap.get(locale)}</LanguageName>
        </PickerTarget>
      }
      size={{ width: 225, height: 640 }}
    >
      {regions}
    </RegionPopup>
  );
};

export default RegionPicker;
