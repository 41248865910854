import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  IntrospectionResultData
} from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import 'isomorphic-fetch';

import { AuthLinkOptions, createAuthLink } from './authLink';
import introspectionQueryResultData from './fragmentTypes.json';
import { StaticContext } from '../types';

interface CreateGalleryClientOptions {
  galleryApiUrl: string;
  authLinkOptions?: AuthLinkOptions;
  fetch?: typeof fetch;
  staticContext: StaticContext;
  initialState?: any;
  ssrMode?: boolean;
}

export function createGalleryClient(options: CreateGalleryClientOptions) {
  const authLink = createAuthLink(options.authLinkOptions);
  const httpLink = createHttpLink({
    uri: options.galleryApiUrl,
    fetch: options.fetch || fetch
  });

  let cache = new InMemoryCache({
    fragmentMatcher: new IntrospectionFragmentMatcher({
      introspectionQueryResultData: introspectionQueryResultData as IntrospectionResultData
    })
  });

  if (options.initialState) {
    cache = cache.restore(options.initialState);
  }

  const localeLink = setContext((_, { headers }) => {
    const locale =
      options.staticContext && options.staticContext.locale
        ? options.staticContext.locale
        : 'en-US';

    return {
      headers: {
        ...headers,
        'accept-language': `${locale},${locale.split('-')[0]};q=0.9`
      }
    };
  });

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([authLink, localeLink, httpLink]),
    ssrMode: options.ssrMode
  });

  return client;
}
