import React from 'react';

type renderBodyTagProps = {
  activeNavItem?: string;
};

const SUPPORTED_NAV_ITEMS = ['clocks', 'apps'];

export const activeNavItemClass = (navItem: string) => `nav-${navItem}-active`;

/**
 * Wrap the Helmet body tag creation to make sure it
 * correctly includes needed attributes. This function should
 * be extended as needed for various uses of the body tag.
 */
export const renderBodyTag = ({ activeNavItem }: renderBodyTagProps) => {
  if (!(activeNavItem && SUPPORTED_NAV_ITEMS.includes(activeNavItem))) {
    return null;
  }
  const className = activeNavItemClass(activeNavItem);
  return <body className={className} />;
};
