import React, { ReactElement } from 'react';
import { Route, RouteComponentProps } from 'react-router';

import { useLocale } from '../hooks';
import { StaticContext } from '../types';
import { normalizedBaseLocales } from '../sharedConfig';

type RenderChildrenProps = RouteComponentProps<
  { locale: string },
  StaticContext
>;

export type PassedProps = RouteComponentProps & {
  locale: string;
  path: string;
  basePath: string;
};

const LocaleRoute: React.FC<
  | {
      component: React.ComponentType<PassedProps>;
      render?: never;
    }
  | {
      component?: never;
      render: (props: PassedProps) => React.ReactNode;
    }
> = ({ component, render }) => {
  const Child: React.FC<RenderChildrenProps> = (props) => {
    const {
      match: {
        url,
        params: { locale: localeParam }
      },
      location: { pathname },
      staticContext
    } = props;

    let path = url.toLowerCase().replace(/\/$/, '');
    const locale = localeParam && localeParam.toLowerCase();

    const isValidLocale = normalizedBaseLocales.has(locale);

    const parsedLocale = isValidLocale ? locale : 'en-us';
    useLocale(parsedLocale);

    if (staticContext) {
      staticContext.locale = parsedLocale;
    }

    if (locale && !isValidLocale) {
      path = '';
    }

    const passedProps = {
      ...props,
      locale,
      path,
      basePath: pathname.replace(path, '')
    };

    if (component) {
      return React.createElement(component, passedProps);
    }

    return typeof render === 'function'
      ? (render(passedProps) as ReactElement)
      : null;
  };

  return <Route path="/:locale([a-z]{2}-[a-zA-Z]{2})?" component={Child} />;
};

export default LocaleRoute;
