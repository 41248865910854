import React, { ReactHTML } from 'react';
import styled, { css, StyledComponent } from 'styled-components';
import { colors } from '../style-constants';
import { below, exactly } from '../media-query-utils';
import { transparentize } from 'polished';
import isPropValid from '@emotion/is-prop-valid';

import arrow from '../images/icons/small-arrow.svg';
import LocaleLink, { LocaleLinkProps } from './LocaleLink';

type LinkProps = LocaleLinkProps & {
  external?: boolean;
  title?: string;
};

type ExternalLinkType = StyledComponent<'a', any> | ReactHTML['a'];
type InternalLinkType =
  | StyledComponent<typeof LocaleLink, any>
  | typeof LocaleLink;

type CreateLink = (
  ExternalLink: ExternalLinkType,
  InternalLink: InternalLinkType
) => React.FC<LinkProps>;

export const Text = styled.div`
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.tangaroa};
`;

export const InfoText = styled(Text)`
  color: ${colors.submarine};
  font-size: 16px;
  margin-bottom: 20px;
`;

const createLink: CreateLink = (ExternalLink, InternalLink) => ({
  to,
  external,
  direct,
  ...props
}) => {
  type AnyProps = { [index: string]: any };
  const validProps: AnyProps = {};

  // This is to prevent our custom props getting into
  // the DOM components which React complains against
  for (const prop in props) {
    if (isPropValid(prop)) {
      validProps[prop] = (props as AnyProps)[prop];
    }
  }

  return external ? (
    <ExternalLink href={to as 'string'} {...validProps} />
  ) : (
    <InternalLink to={to} direct={direct} {...validProps} />
  );
};

export const BaseLink = createLink(
  ('a' as unknown) as ReactHTML['a'],
  LocaleLink
);

const baseLinkNoStyle = css`
  color: inherit;
  text-decoration: none;
`;

export const NoStyleLink = styled(BaseLink)`
  ${baseLinkNoStyle}
`;

export const baseFitLinkStyle = css`
  color: ${colors.irisBlue};
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;

  ${({ showArrow }: { showArrow?: boolean }) =>
    showArrow
      ? css`
    &:after {
      content: url('${arrow}');
      position: relative;
      top: 2px;
      left: 4px;
    }
  `
      : ''}
`;

export const FitLink = styled(BaseLink)`
  ${baseFitLinkStyle}
`;

export const baseButtonLinkStyle = css`
  padding: 12px 24px;
  color: white;
  background-color: ${colors.irisBlue};
  border-radius: 20px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  white-space: nowrap;
`;

export const ButtonLink = styled(BaseLink)`
  ${baseButtonLinkStyle}
`;

export const smallButtonStyle = css`
  display: flex;
  min-width: 40px;
  height: 40px;
  border-radius: 15px;

  align-items: center;
  justify-content: center;

  text-decoration: none;
`;

export const BaseSection = styled.section`
  width: 1200px;
  margin-left: auto;
  margin-right: auto;

  ${below('largeDesktop')} {
    width: 100%;
    padding-left: 120px;
    padding-right: 120px;
  }

  ${below('smallDesktop')} {
    padding-left: 116px;
    padding-right: 116px;
  }

  //A little funny business here to get things to look better
  ${below('smallDesktop', -24)} {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  // iPad specific width
  ${exactly('tablet')} {
    padding-left: 11vw;
    padding-right: 11vw;
  }

  ${below('mobile')} {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const Section = styled(BaseSection)`
  padding-bottom: 50px;

  margin-top: 10px;
  ${below('mobile')} {
    padding-top: 40px;
    padding-bottom: 40px;

    &:first-of-type {
      padding-top: 20px;
    }
  }
`;

export const CalloutSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-top: 106px;
  padding-bottom: 106px;
`;

export const ErrorCallout = styled(CalloutSection)`
  background-color: ${colors.ivory};
`;

export const BannerTitle = styled(Text)`
  font-size: 40px;
  font-weight: 600;

  ${below('smallDesktop')} {
    font-size: 30px;
  }

  ${below('mobile')} {
    font-size: 24px;
  }
`;

export const BannerSubTitle = styled(Text)`
  font-size: 14px;
  line-height: 1.57;
  color: ${colors.submarine};
  margin-bottom: 20px;
`;

export const Badge = styled(Text)<{ inverse?: boolean }>`
  margin-right: 5px;
  margin-bottom: 24px;
  padding: 4px 12px;

  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 1px;

  border-radius: 17px;

  ${({ inverse }) =>
    inverse
      ? css`
          color: ${colors.irisBlue};
          background-color: ${colors.white};
          border: solid 1px ${colors.pattensBlue};
        `
      : css`
          color: ${colors.creamCan};
          background-color: ${transparentize(0.9, colors.creamCan)};
          border: solid 1px ${colors.ginFizz};
        `}
`;
