import germanFlag from './images/flag-icons/4x3/de.svg';
import gbFlag from './images/flag-icons/4x3/gb.svg';
import spanishFlag from './images/flag-icons/4x3/es.svg';
// import latinFlag from './images/flag-icons/4x3/mx.svg';
import canadianFlag from './images/flag-icons/4x3/ca.svg';
import frenchFlag from './images/flag-icons/4x3/fr.svg';
import italianFlag from './images/flag-icons/4x3/it.svg';
import japaneseFlag from './images/flag-icons/4x3/jp.svg';
import koreanFlag from './images/flag-icons/4x3/kr.svg';
import netherlandsFlag from './images/flag-icons/4x3/nl.svg';
import swedishFlag from './images/flag-icons/4x3/se.svg';
import chineseFlag from './images/flag-icons/4x3/cn.svg';
import taiwanFlag from './images/flag-icons/4x3/tw.svg';
import usFlag from './images/flag-icons/4x3/us.svg';
import czechFlag from './images/flag-icons/4x3/cz.svg';
import indonesianFlag from './images/flag-icons/4x3/id.svg';
import polishFlag from './images/flag-icons/4x3/pl.svg';
import brazilFlag from './images/flag-icons/4x3/br.svg';
import romanianFlag from './images/flag-icons/4x3/ro.svg';
import russianFlag from './images/flag-icons/4x3/ru.svg';
import norwegianFlag from './images/flag-icons/4x3/no.svg';

// Endpoint on Fitbit API server for graphql requests
export const galleryGraphqlEndpoint = '/1/desktop-gallery/graphql';

// Endpoint on desktop-gallery server for getting latest config
export const refreshConfigEndpoint = '/_internal/config';

export const allAppsCategoryId = 'a406cc65-ebae-4e47-b1bd-7f86800bb2c6';
export const allClocksCategoryId = 'c3c6d097-a255-4613-b817-d5d693f13318';
export const allCategoriesIds = [allAppsCategoryId, allClocksCategoryId];

export const isServer = typeof window === 'undefined';
export const isWeb = !isServer;

export const loadingDelay = 3000;

// Paging options
export const appsPageSize = 24;
export const numVisiblePages = 5;
export const numPrevPages = numVisiblePages;
export const numNextPages = numVisiblePages;

const normalizeLocales = (locales: Set<string>) =>
  new Set([...locales].map((locale) => locale.toLowerCase()));

export const localeMap = new Map([
  ['cs-CZ', 'Česko'],
  ['de-DE', 'Deutschland'],
  ['en-GB', 'United Kingdom'],
  ['es-ES', 'España'],
  // ['es-LA', 'Latin America (Spanish)'],
  ['fr-CA', 'Canada (français)'],
  ['en-CA', 'Canada'],
  ['fr-FR', 'France'],
  ['id-ID', 'Indonesia'],
  ['it-IT', 'Italia'],
  ['ja-JP', '日本'],
  ['ko-KR', '대한민국'],
  ['nl-NL', 'Nederland'],
  ['en-NL', 'Nederland (Engels)'],
  ['nb-NO', 'Norge'],
  ['pl-PL', 'Polska'],
  ['pt-BR', 'Brasil'],
  ['ru-RU', 'Россия'],
  ['ro-RO', 'România'],
  ['sv-SE', 'Sverige (svenska)'],
  ['en-SE', 'Sverige (engelska)'],
  ['zh-CN', '中国'],
  ['zh-TW', '臺灣']
]);

export const flagMap = new Map([
  ['cs-cz', czechFlag],
  ['de-de', germanFlag],
  ['en-gb', gbFlag],
  ['es-es', spanishFlag],
  // ['es-la', latinFlag],
  ['fr-ca', canadianFlag],
  ['en-ca', canadianFlag],
  ['fr-fr', frenchFlag],
  ['id-id', indonesianFlag],
  ['it-it', italianFlag],
  ['ja-jp', japaneseFlag],
  ['ko-kr', koreanFlag],
  ['nl-nl', netherlandsFlag],
  ['nb-no', norwegianFlag],
  ['pl-pl', polishFlag],
  ['pt-br', brazilFlag],
  ['ro-ro', romanianFlag],
  ['ru-ru', russianFlag],
  ['en-nl', netherlandsFlag],
  ['sv-se', swedishFlag],
  ['en-se', swedishFlag],
  ['zh-cn', chineseFlag],
  ['zh-tw', taiwanFlag],
  ['en-us', usFlag]
]);

export const baseLocales = new Set(localeMap.keys());
export const normalizedBaseLocales = normalizeLocales(baseLocales);
export const allLocalesMap = localeMap.set('en-US', 'United States');
export const normalizedAllLocalesMap = new Map(
  Array.from(allLocalesMap).map(
    ([key, value]) => [key.toLowerCase(), value] as [string, string]
  )
);
export const allLocales = new Set(allLocalesMap.keys());

export const normalizedAllLocales = normalizeLocales(allLocales);
