import React from 'react';
import Delay from 'react-delay';

import { CalloutSection } from './basic-components';
import { colors } from '../style-constants';
import styled from 'styled-components';
import { loadingDelay } from '../sharedConfig';
import { useI18n } from '../hooks';

const LoadingCallout = styled(CalloutSection)`
  background-color: ${colors.ivory};
  text-align: center;
`;

export const LoadingPage: React.FC = () => {
  const { t } = useI18n();

  return (
    <Delay wait={loadingDelay}>
      <LoadingCallout>
        <h2>{t`Hold on. We're still loading your content...`}</h2>
      </LoadingCallout>
    </Delay>
  );
};
