import { useState, useLayoutEffect, useContext } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { Location } from 'history';

import { isWeb, isServer } from './sharedConfig';
import CookiesContext from './CookiesContext';
import I18nContext from './I18nContext';

export const useMatchMedia: (matchMedia: string) => [boolean] = (
  mediaQuery
) => {
  const [matches, setMatches] = useState(false);

  if (isWeb) {
    useLayoutEffect(() => {
      const mediaQueryList = window.matchMedia(mediaQuery);

      const updateMatches = () => setMatches(mediaQueryList.matches);

      mediaQueryList.addListener(updateMatches);
      updateMatches();

      return () => mediaQueryList.removeListener(updateMatches);
    }, [matches]);
  }

  return [matches];
};

const validOpenwith = new Set([
  'alphabit',
  'betabit',
  'devbit',
  'debugbit',
  'fitbit',
  'internalbit',
  'fishbit',
  'dogbit'
]);

const encodeDecodeOpenwith = (input: string | string[] | null | undefined) => {
  if (!input) {
    return;
  }

  if (Array.isArray(input)) {
    input = input[0];
  }

  return validOpenwith.has(input) ? input : undefined;
};

const OpenwithParam = {
  encode: encodeDecodeOpenwith,
  decode: encodeDecodeOpenwith
};

export const useOpenwith = () => {
  const cookies = useContext(CookiesContext);
  let [openwith] = useQueryParam('openwith', OpenwithParam);

  if (typeof openwith !== 'undefined') {
    const options: { expires?: number | Date; httpOnly: boolean } = {
      httpOnly: false
    };

    // Reset cookie
    if (openwith === 'fitbit') {
      options.expires = new Date(0);
    }

    cookies.set('openwith', openwith, options);
  }

  if (!openwith) {
    openwith = cookies.get('openwith') || 'fitbit';
  }

  return openwith;
};

export const usePagingProps = (location: Location) => {
  const [pageKey] = useQueryParam('pageKey', StringParam);
  return {
    pageKey,
    pathname: location.pathname
  };
};

export const useLocale = (() => {
  let called = false;

  return (urlLocale?: string) => {
    const i18n = useContext(I18nContext);

    const { addLocale, useLocale: setLocale } = i18n;

    if (isWeb && !called) {
      try {
        const locale = (window as any).__LOCALE__;
        const localeData = (window as any).__LOCALE_DATA__;

        addLocale(locale, localeData);
        setLocale(locale);
      } catch (err) {
        // Ignore locales with no translations
        // This shouldn't happen in production
        console.error(err);
      }
      called = true;
    } else if (urlLocale && isServer) {
      setLocale(urlLocale);
    }
  };
})();

export const useI18n = () => useContext(I18nContext);
