import * as React from 'react';

import { ErrorPage } from './ErrorPage';
import { useI18n } from '../hooks';

export const PageNotFound: React.FC = ({ staticContext }: any) => {
  const { t } = useI18n();

  if (staticContext) {
    staticContext.statusCode = 404;
  }

  return (
    <ErrorPage>{t`Sorry, that page is unavailable or does not exist.`}</ErrorPage>
  );
};
