export type AuthToken = {
  accessToken: string;
  expiresAt: number; // expiration time in UNIX seconds
};

export function isExpired(authToken: AuthToken): boolean {
  return authToken.expiresAt * 1000 < Date.now();
}

export function isExpiringSoon(authToken: AuthToken, seconds: number): boolean {
  return authToken.expiresAt * 1000 - Date.now() < seconds * 1000;
}
