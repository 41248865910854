import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { isWeb } from '../sharedConfig';
import { useMatchMedia } from '../hooks';
import { colors } from '../style-constants';

type Size = {
  width: number;
  height: number;
};

type Dimensions = Size & {
  top: number;
  left: number;
};

type PopupProps = {
  target: React.ReactNode;
  size: Size;
  container?: HTMLElement;
};

type GetPositionArgs = {
  targetDimensions: Dimensions;
  contentSize: Size;
  outerBounds: Dimensions;
  outerPadding?: number;
};

type Position = {
  top: number;
  left: number;
  width: number;
  height: number;
};

const getPosition: (options: GetPositionArgs) => Position = ({
  targetDimensions,
  contentSize,
  outerBounds,
  outerPadding = 20
}) => {
  const { height, width } = contentSize;
  const top = targetDimensions.top - height;
  let left = targetDimensions.left + (targetDimensions.width - width) / 2;

  const contentRight = left + width;

  if (contentRight > outerBounds.width) {
    left = outerBounds.width - (width + outerPadding);
  }

  if (left < outerBounds.left) {
    left = outerBounds.left + outerPadding;
  }

  return {
    top,
    left,
    width,
    height
  };
};

const PopupContainer = styled.div.attrs(
  // Optimization recommended by styled-components warning
  // since it was creating too many unique styles
  ({ position }: { position: Position }) => ({
    style: {
      top: `${position.top - 10}px`,
      left: `${position.left}px`,
      width: `${position.width}px`,
      height: `${position.height}px`
    }
  })
)`
  position: absolute;
  ${({
    active
  }: {
    active: boolean;
    /* I have to add this so the typing works correctly :( */
    position: Position;
  }) =>
    active
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}

  transition: visibility 300ms, opacity 300ms;

  border-radius: 15px;
  box-shadow: 0 0 15px 0 rgba(64, 95, 107, 0.1);
  background-color: ${colors.white};

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 10px;
  }
`;

const Target = styled.div`
  position: relative;

  ${({ active }: { active: boolean }) =>
    active
      ? css`
          &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: -20px;
            width: 0;
            height: 0;
            transform: translateX(-50%) rotate(-45deg);
            border: 8px solid transparent;
            border-color: ${colors.white};
            box-shadow: -3 3 15px -5px rgba(64, 95, 107, 0.1);
          }
        `
      : ''}
`;

const BodyClicker = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ active }) => (active ? '' : 'display: none;')}
`;

const Popup: React.FC<PopupProps> = ({
  target,
  size,
  container,
  children,
  ...props
}) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState();
  const [active, setActiveState] = useState(false);
  const [popupFocused, setPopupFocused] = useState(false);
  const [canHover] = useMatchMedia('(hover: hover)');

  const processPosition = useCallback(() => {
    if (!targetRef.current) {
      return;
    }

    const outerContainer =
      typeof container !== 'undefined' ? container : document.body;

    const {
      offsetTop: top,
      offsetLeft: left,
      offsetWidth: width,
      offsetHeight: height
    } = targetRef.current;

    setPosition(
      getPosition({
        targetDimensions: { top, left, width, height },
        contentSize: size,
        outerBounds: outerContainer.getBoundingClientRect()
      })
    );
  }, [targetRef.current, container]);

  if (isWeb) {
    useLayoutEffect(() => {
      window.addEventListener('resize', processPosition);

      return () => window.removeEventListener('resize', processPosition);
    }, [active, position]);
  }

  const setActive = useCallback(
    (newActiveState) => {
      if (targetRef.current === null) {
        return;
      }

      processPosition();
      setActiveState(newActiveState);
    },
    [active, position]
  );

  const onHover = useCallback(() => canHover && setActive(true), [canHover]);

  const onLeaveHover = useCallback(() => setActive(false), []);

  const toggleActive = useCallback(() => setActive(!active), [active]);

  const forcePopupFocused = useCallback(() => setPopupFocused(true), []);
  const forcePopupUnfocused = useCallback(() => setPopupFocused(false), []);

  return (
    <React.Fragment>
      {position && (
        <React.Fragment>
          <BodyClicker active={active} onClick={onLeaveHover} />
          <PopupContainer
            active={active || popupFocused}
            onMouseEnter={forcePopupFocused}
            onMouseLeave={forcePopupUnfocused}
            position={position}
          >
            {children}
          </PopupContainer>
        </React.Fragment>
      )}
      <Target
        ref={targetRef}
        active={active}
        onClick={toggleActive}
        onMouseEnter={onHover}
        onMouseLeave={onLeaveHover}
        {...props}
      >
        {target}
      </Target>
    </React.Fragment>
  );
};

export default Popup;
