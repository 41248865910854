import React from 'react';
import Helmet from 'react-helmet';
import styled, { css } from 'styled-components';

import { BaseSection, FitLink, NoStyleLink, Section } from './basic-components';
import SearchBar from './SearchBar';
import { colors } from '../style-constants';
import { below } from '../media-query-utils';
import logo from '../images/icons/logo.svg';
import { activeNavItemClass, renderBodyTag } from '../helmet-utils';
import LocaleRoute, { PassedProps } from './LocaleRoute';
import { LoadingPage } from './LoadingPage';
import { useI18n } from '../hooks';
import {
  GetEEAUserVariables,
  GetEEAUserResponse,
  getEEAUser
} from '../graphql/getPageLayout';
import { ChildDataProps, graphql } from 'react-apollo';

export const HeaderBackground = styled.header`
  width: 100%;

  background-color: ${colors.white};
  box-shadow: 0 -1px 0 0 rgba(213, 213, 213, 0.5),
    0 1px 0 0 rgba(213, 213, 213, 0.5);

  z-index: 1;
`;

export const HeaderWrapper = styled(BaseSection)`
  position: relative;
  display: flex;
  height: 60px;

  align-items: center;
  justify-content: space-between;

  ${({ isSearchPage }: { isSearchPage?: boolean }) =>
    isSearchPage
      ? css`
          ${below('mobile')} {
            margin-bottom: 60px;
          }
        `
      : ''}
`;

type ActiveNavItemProps = {
  item?: string;
};

/**
 * Convenience component for creating a Helmet with a <body> tag
 * exclusively used for setting the active nav item.
 *
 * Warning: This will clobber (or be clobbered by) other Helmet <body>
 * tag uses, so use the renderBodyTag utility function from
 * helmet-utils to allow merging them.
 */
export const ActiveNavItem = ({ item }: ActiveNavItemProps) => (
  <Helmet>{renderBodyTag({ activeNavItem: item })}</Helmet>
);

const HeaderLogo = styled(NoStyleLink)`
  width: 170px;
  height: 24px;

  background-image: url(${logo});
  background-size: cover;

  line-height: 0;
  font-size: 0;
  color: transparent;

  z-index: 1;

  ${below('smallTablet')} {
    width: 30px;
    height: 30px;
  }
`;

const LinkRow = styled.nav`
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  justify-content: center;
`;

const HeaderLink = styled(FitLink)`
  padding-top: 3px;
  color: ${colors.submarine};
  ${({ navItem }: { navItem: string }) => css`
    .${activeNavItemClass(navItem)} & {
      color: ${colors.irisBlue};
    }
  `}

  & + & {
    margin-left: 40px;

    ${below('tablet')} {
      margin-left: 20px;
    }

    ${below('mobile')} {
      margin-left: 30px;
    }
  }
`;

export interface HeaderProps extends PassedProps {
  clientIpAddress?: string;
}

type ChildProps = ChildDataProps<
  HeaderProps,
  GetEEAUserVariables,
  GetEEAUserResponse
>;

const Header: React.FC<ChildProps> = (props) => {
  const { t } = useI18n();
  const { location, data } = props;
  const { loading } = data;

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <HeaderBackground role="banner" aria-label={t`Fitbit Gallery Header`}>
      <HeaderWrapper isSearchPage={location.pathname === '/search'}>
        <HeaderLogo
          id="fitbitGalleryLogo"
          to="/"
        >{t`Fitbit Gallery Logo`}</HeaderLogo>
        <LinkRow role="navigation" aria-label={t`Gallery Navigation`}>
          <HeaderLink
            title={t`Discover Clock faces`}
            navItem="clocks"
            to="/clocks"
            id="clocksHeaderLink"
          >
            {t`Clocks`}
          </HeaderLink>
          <HeaderLink
            title={t`Discover Apps`}
            navItem="apps"
            to="/apps"
            id="appsHeaderLink"
          >
            {t`Apps`}
          </HeaderLink>
        </LinkRow>
        {!data.isEEAUser ? <LocaleRoute component={SearchBar} /> : null}
      </HeaderWrapper>
    </HeaderBackground>
  );
};

const SubHeaderWrapper = styled.div`
  width: 100%;

  background-color: ${colors.whiteSmoke};
  border-bottom: 1px solid ${colors.linkWater};
`;

const SubHeaderSection = styled(Section)`
  &&& {
    padding-top: 13px;
    padding-bottom: 13px;
  }
`;

export const SubHeader: React.FC<{}> = (props) => (
  <SubHeaderWrapper>
    <SubHeaderSection>{props.children}</SubHeaderSection>
  </SubHeaderWrapper>
);

export default graphql<
  HeaderProps,
  GetEEAUserResponse,
  GetEEAUserVariables,
  ChildProps
>(getEEAUser, {
  options: (props) => ({
    variables: {
      clientIpAddress: props.clientIpAddress
    }
  })
})(Header);
