import { css } from 'styled-components';

export default css`
  @font-face {
    font-family: 'Proxima Nova Regular';
    src: url(/fonts/proxima-nova-regular.eot);
    src: url(/fonts/proxima-nova-regular.eot#iefix) format('embedded-opentype'),
      url(/fonts/proxima-nova-regular.woff) format('woff'),
      url(/fonts/proxima-nova-regular.ttf) format('truetype'),
      url(/fonts/proxima-nova-regular.svg#ProximaNovaRegular) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Proxima Nova Bold';
    src: url(/fonts/proxima-nova-bold.eot);
    src: url(/fonts/proxima-nova-bold.eot#iefix) format('embedded-opentype'),
      url(/fonts/proxima-nova-bold.woff) format('woff'),
      url(/fonts/proxima-nova-bold.ttf) format('truetype'),
      url(/fonts/proxima-nova-bold.svg#ProximaNovaBold) format('svg');
    font-weight: normal;
    font-style: normal;
  }
`;
