import React, { useCallback } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import LocaleRoute from './LocaleRoute';

export type LocaleLinkProps = LinkProps & {
  direct?: boolean;
};

const LocaleLink: React.FC<LocaleLinkProps> = (props) => {
  const renderLink = useCallback(
    ({ path }) => {
      const { direct, to, ...restProps } = props;
      return <Link {...restProps} to={`${direct ? '' : path}${to}`} />;
    },
    [props, props.to, props.direct]
  );

  return <LocaleRoute render={renderLink} />;
};

export default LocaleLink;
