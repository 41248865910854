import React, { Fragment, useCallback } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import loadable from '@loadable/component';
import Helmet from 'react-helmet';

import proximaNovaFont from './proxima-nova-font';

import { extractAccessKeyFromLocation } from './utils';
import { FilterType } from './constants';
import { colors } from './style-constants';
import { DiscoveryProps } from './pages/Discovery';
import Header, { ActiveNavItem } from './components/Header';
import { HeaderProps } from './components/Header';
import Footer from './components/Footer';
import { PageNotFound } from './pages/404';
import { ErrorPage } from './pages/ErrorPage';
import {
  allAppsCategoryId,
  allClocksCategoryId,
  normalizedBaseLocales
} from './sharedConfig';
import { useI18n, usePagingProps, useOpenwith } from './hooks';
import LocaleRoute, { PassedProps } from './components/LocaleRoute';
import { StringParam, useQueryParams } from 'use-query-params';
import { StaticContext } from './types';

type AppIdParam = {
  appId: string;
};

type BasePathParam = {
  basePath: string;
};

type CategoryIdParam = {
  categoryId: string;
};

type CollectionIdParam = {
  collectionId: string;
};

type DeveloperAppsPageParam = {
  developerProfileId: string;
};

type CollectionPageProps = RouteComponentProps<CollectionIdParam>;
type CategoryPageProps = RouteComponentProps<CategoryIdParam>;
type PathNavigationProps = RouteComponentProps<BasePathParam>;
type AppPageProps = RouteComponentProps<AppIdParam>;
type DeveloperAppsPageProps = RouteComponentProps<DeveloperAppsPageParam>;

const UUID_REGEX =
  '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';

const Home = loadable(() => import(/* webpackPrefetch: true */ './pages/Home'));
const Discovery = loadable(() =>
  import(/* webpackPrefetch: true */ './pages/Discovery')
);
const Details = loadable(() =>
  import(/* webpackPrefetch: true */ './pages/Details')
);
const SearchParent = loadable.lib(() =>
  import(/* webpackPrefetch: true */ './pages/Search')
);
const CollectionParent = loadable.lib(() =>
  import(/* webpackPrefetch: true */ './pages/Collection')
);

const MobileAppRedirect = loadable(() =>
  import(/* webpackPrefetch: true */ './pages/MobileAppRedirect')
);

const DeveloperApps = loadable(() =>
  import(/* webpackPrefetch: true */ './pages/DeveloperApps')
);

export const GlobalStyle = createGlobalStyle`
  ${proximaNovaFont}

  html, body, #root {
    height: 100%;

    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Proxima Nova Regular', Arial, sans-serif;
    background-color: ${colors.ivory};

    overflow-x: hidden;
  }

  *, *:before, *:after {
    box-sizing: border-box;
  }

  #root {
    display: flex;
    flex-direction: column;
  }
`;

const AppBody = styled.main`
  position: relative;
  flex: 1;
`;

const DiscoveryPage: React.FC<DiscoveryProps> = (props) => {
  const [{ layoutId }] = useQueryParams({
    layoutId: StringParam
  });

  return <Discovery layoutId={layoutId} {...props} />;
};

const getClientIpAddress = (
  props:
    | PassedProps
    | CategoryPageProps
    | CollectionPageProps
    | AppPageProps
    | DeveloperAppsPageProps
) => {
  return props.staticContext &&
    (props.staticContext as StaticContext).clientIpAddress
    ? (props.staticContext as StaticContext).clientIpAddress
    : undefined;
};

const AppDiscoveryPage: React.FC<PassedProps> = (props) => {
  const clientIpAddress = getClientIpAddress(props);

  return (
    <DiscoveryPage type="APP" clientIpAddress={clientIpAddress} {...props} />
  );
};

const ClockDiscoveryPage: React.FC<PassedProps> = (props) => {
  const clientIpAddress = getClientIpAddress(props);

  return (
    <DiscoveryPage type="CLOCK" clientIpAddress={clientIpAddress} {...props} />
  );
};

const HomePage: React.FC = () => <Home />;

const PathNavigation = ({ match }: PathNavigationProps) => {
  const basePath = match && match.params && match.params.basePath;
  return <ActiveNavItem item={basePath} />;
};

const SearchPage: React.FC<PassedProps> = (props) => {
  const [{ terms, type }] = useQueryParams({
    terms: StringParam,
    type: StringParam
  });

  const clientIpAddress = getClientIpAddress(props);

  if (!terms) {
    return (
      <SearchParent>{({ SearchSplash }) => <SearchSplash />}</SearchParent>
    );
  }

  const parsedTerms = Array.isArray(terms) ? terms.join(' ') : terms;

  return (
    <SearchParent>
      {({ default: Search }) => (
        <LocaleRoute
          /* tslint:disable jsx-no-lambda */
          render={({ path }) => (
            <Search
              terms={parsedTerms.replace(/[<>]/g, '')}
              type={type as FilterType}
              path={path}
              clientIpAddress={clientIpAddress}
            />
          )}
        />
      )}
    </SearchParent>
  );
};

const Head: React.FC<PassedProps> = ({
  locale,
  basePath,
  location: { search }
}) => {
  const { t } = useI18n();

  // There is no good way to get the host from the server
  // that can be used here, since this only matters for
  // production traffic we can default to using
  // https://gallery.fitbit.com
  const createHref = useCallback(
    (path: string) => `https://gallery.fitbit.com${path}${search}`,
    []
  );

  const defaultTitle = t`Fitbit App Gallery`;

  return (
    <Helmet defaultTitle={defaultTitle} titleTemplate={`%s | ${defaultTitle}`}>
      <html lang={locale} />
      <base href="/" />
      <meta name="referrer" content="origin" />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@fitbit" />
      {/* Alternate link tags are used to tell search engines alternative languages */}
      <link rel="alternate" href={createHref(basePath)} hrefLang="x-default" />
      <link rel="alternate" href={createHref(basePath)} hrefLang="en-us" />
      {Array.from(normalizedBaseLocales).map((alternateLocale) => (
        <link
          rel="alternate"
          href={createHref(`/${alternateLocale}${basePath}`)}
          hrefLang={alternateLocale}
          key={alternateLocale}
        />
      ))}
    </Helmet>
  );
};

interface CollectionPagePropsExtended
  extends RouteComponentProps<{ collectionId: string }> {
  clientIpAddress?: string;
}

const CollectionPage: React.FC<CollectionPagePropsExtended> = (props) => {
  const pagingProps = usePagingProps(props.location);
  return (
    <CollectionParent>
      {({ CollectionPage: Page }) => (
        <Page
          id={props.match.params.collectionId}
          clientIpAddress={props.clientIpAddress}
          {...pagingProps}
        />
      )}
    </CollectionParent>
  );
};

const CollectionPageExtended: React.FC<CollectionPageProps> = (props) => {
  const clientIpAddress = getClientIpAddress(props);

  return <CollectionPage clientIpAddress={clientIpAddress} {...props} />;
};

interface CategoryPagePropsExtended
  extends RouteComponentProps<{ categoryId: string }> {
  clientIpAddress?: string;
}

const CategoryPage: React.FC<CategoryPagePropsExtended> = (props) => {
  const pagingProps = usePagingProps(props.location);
  return (
    <CollectionParent>
      {({ CategoryPage: Page }) => (
        <Page
          id={props.match.params.categoryId}
          clientIpAddress={props.clientIpAddress}
          {...pagingProps}
        />
      )}
    </CollectionParent>
  );
};

const CategoryPageExtended: React.FC<CategoryPageProps> = (props) => {
  const clientIpAddress = getClientIpAddress(props);

  return <CategoryPage clientIpAddress={clientIpAddress} {...props} />;
};

interface AppsPagePropsExtended extends RouteComponentProps<{ appId: string }> {
  clientIpAddress?: string;
}

const DetailsPage: React.FC<AppsPagePropsExtended> = ({
  match,
  location,
  clientIpAddress
}) => (
  <Details
    id={match.params.appId}
    accessKey={extractAccessKeyFromLocation(location as any)}
    clientIpAddress={clientIpAddress}
  />
);

const DetailsPageExtended: React.FC<AppPageProps> = (props) => {
  const clientIpAddress = getClientIpAddress(props);

  return <DetailsPage clientIpAddress={clientIpAddress} {...props} />;
};

const MobileAppRedirectPage: React.FC<AppPageProps> = ({ match }) => (
  <MobileAppRedirect
    appId={match.params.appId}
    accessKey={extractAccessKeyFromLocation(location as any)}
  />
);

interface AllAppsCategoryPropsExtended extends RouteComponentProps {
  clientIpAddress?: string;
}

const AllAppsCategoryPage: React.FC<AllAppsCategoryPropsExtended> = (props) => {
  const pagingProps = usePagingProps(props.location);
  return (
    <CollectionParent>
      {({ CategoryPage: Page }) => (
        <Page
          id={allAppsCategoryId}
          clientIpAddress={props.clientIpAddress}
          {...pagingProps}
        />
      )}
    </CollectionParent>
  );
};

const AllClocksCategoryPage: React.FC<AllAppsCategoryPropsExtended> = ({
  match,
  location,
  clientIpAddress
}) => {
  const pagingProps = usePagingProps(location);
  return (
    <CollectionParent>
      {({ CategoryPage: Page }) => (
        <Page
          id={allClocksCategoryId}
          clientIpAddress={clientIpAddress}
          {...pagingProps}
        />
      )}
    </CollectionParent>
  );
};

const AllClocksCategoryPageExtended: React.FC<PassedProps> = (props) => {
  const clientIpAddress = getClientIpAddress(props);

  return <AllClocksCategoryPage clientIpAddress={clientIpAddress} {...props} />;
};

const AllAppsCategoryPageExtended: React.FC<PassedProps> = (props) => {
  const clientIpAddress = getClientIpAddress(props);

  return <AllAppsCategoryPage clientIpAddress={clientIpAddress} {...props} />;
};

const LocaleResetPage: React.FC<RouteComponentProps> = ({
  match: { url },
  location: { pathname }
}) => <Redirect to={`${pathname.replace(url, '')}`} />;

interface DeveloperAppsPagePropsExtended
  extends RouteComponentProps<{ developerProfileId: string }> {
  clientIpAddress?: string;
}

const DeveloperAppsPageExtended: React.FC<DeveloperAppsPageProps> = (props) => {
  const clientIpAddress = getClientIpAddress(props);

  return <DeveloperAppsPage clientIpAddress={clientIpAddress} {...props} />;
};

const DeveloperAppsPage: React.FC<DeveloperAppsPagePropsExtended> = (props) => (
  <DeveloperApps
    developerProfileId={props.match.params.developerProfileId}
    clientIpAddress={props.clientIpAddress}
  />
);

const HeaderComponent: React.FC<HeaderProps> = (props) => {
  return <Header {...props} />;
};

const HeaderComponentTest: React.FC<PassedProps> = (props) => {
  const clientIpAddress = getClientIpAddress(props);

  return <HeaderComponent clientIpAddress={clientIpAddress} {...props} />;
};

const AllRoutes: React.FC<PassedProps> = (props) => {
  const path = props.path;
  return (
    <AppBody role="main" id="main">
      <Route exact path={`${path}/:basePath`} component={PathNavigation} />
      <Switch>
        <Route path="/en-us" component={LocaleResetPage} />
        <Route exact path={`${path}/`} component={HomePage} />
        <Route
          path={`${path}/details/:appId(${UUID_REGEX})/openapp`}
          component={MobileAppRedirectPage}
        />
        <Route
          path={`${path}/developer/:developerProfileId(${UUID_REGEX})`}
          component={DeveloperAppsPageExtended}
        />
        <Route
          path={`${path}/details/:appId(${UUID_REGEX})`}
          component={DetailsPageExtended}
        />
        <Route exact path={`${path}/apps`} component={AppDiscoveryPage} />
        <Route
          path={`${path}/apps/all`}
          component={AllAppsCategoryPageExtended}
        />
        <Route exact path={`${path}/clocks`} component={ClockDiscoveryPage} />
        <Route
          path={`${path}/clocks/all`}
          component={AllClocksCategoryPageExtended}
        />
        <Route
          path={`${path}/collection/:collectionId(${UUID_REGEX})`}
          component={CollectionPageExtended}
        />
        <Route
          path={`${path}/category/:categoryId(${UUID_REGEX})`}
          component={CategoryPageExtended}
        />
        <Route path={`${path}/search`} component={SearchPage} />
        <Route path={`${path}/error/apps|clocks`} component={ErrorPage} />
        <Route component={PageNotFound} />
      </Switch>
    </AppBody>
  );
};

const App: React.FC = () => {
  // Ensure this is always running (to set/read cookies)
  useOpenwith();

  return (
    <Fragment>
      <LocaleRoute component={Head} />
      <GlobalStyle />
      <LocaleRoute component={HeaderComponentTest} />
      <LocaleRoute component={AllRoutes} />
      <Footer />
    </Fragment>
  );
};

export default App;
