import { setContext } from 'apollo-link-context';
import { AuthToken } from './AuthToken';

export interface AuthLinkOptions {
  getAuthToken?: () => Promise<AuthToken>;
}

export const createAuthLink = ({ getAuthToken }: AuthLinkOptions = {}) =>
  setContext(async () => {
    if (getAuthToken) {
      const authToken = await getAuthToken();

      return {
        headers: {
          Authorization: `Bearer ${authToken.accessToken}`
        }
      };
    }

    return {};
  });
