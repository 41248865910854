import React, { useState, useCallback, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useQueryParam, StringParam } from 'use-query-params';

import { colors } from '../style-constants';
import { below } from '../media-query-utils';
import desktopMagnifyingGlass from '../images/icons/magnifying-glass-green.svg';
import mobileMagnifyingGlass from '../images/icons/magnifying-glass.svg';
import close from '../images/icons/close.svg';
import { BaseLink } from './basic-components';
import { useI18n } from '../hooks';
import { PassedProps } from './LocaleRoute';

type SearchProps = {
  active?: boolean;
  transition?: boolean;
  isSearchPage?: boolean;
};

type SearchBarProps = PassedProps;

const resetStyling = css`
  // Reset styling
  &,
  &:focus {
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    color: inherit;
    background-color: inherit;
    font: inherit;
  }
`;

const MagnifyingGlass = styled.div`
  width: 14px;
  height: 14px;

  background-image: url(${desktopMagnifyingGlass});
  background-size: contain;
  background-repeat: no-repeat;

  ${below('mobile')} {
    width: 20px;
    height: 20px;

    background-image: url(${mobileMagnifyingGlass});
  }

  ${resetStyling}
`;

export const Close = styled.div`
  width: 12px;
  height: 12px;

  background-image: url(${close});
  background-size: contain;
  background-repeat: no-repeat;

  ${below('mobile')} {
    width: 16px;
    height: 16px;
  }

  ${resetStyling}
`;

const Position = styled.div`
  ${below('mobile')} {
    position: absolute;
    right: -10px;
  }

  ${({ absolute }: { absolute?: boolean }) => {
    const activeStyle = css`
      position: absolute;
      right: 0px;

      ${below('mobile')} {
        right: 10px;
      }
    `;

    return absolute
      ? activeStyle
      : css`
          /* Fallback logic for no-js */
          div:focus-within > & {
            ${activeStyle}
          }
        `;
  }}
`;

const clickTargetStyle = css`
  display: flex;
  width: 40px;
  min-width: 40px;
  height: 40px;

  justify-content: center;
  align-items: center;

  ${resetStyling}
`;

const ClickTarget = styled(BaseLink)`
  ${clickTargetStyle}
`;

const ClickTargetButton = styled.button`
  ${clickTargetStyle}
`;

const Search = styled.div`
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;

  justify-content: space-between;
  align-items: center;

  border: 1px solid ${colors.linkWater};
  border-radius: 15px;
  background-color: ${colors.white};

  transition: width 300ms;

  overflow: hidden;

  z-index: 2;

  ${below('mobile')} {
    width: 40px;
    height: 100%;
    padding: 0;

    border: none;

    transition: ${({ isSearchPage = false }) =>
      isSearchPage ? 'width 200ms' : 'initial'};

    overflow: initial;
  }

  ${({ active, isSearchPage }: SearchProps) => {
    const activeStyle = css`
      width: 240px;

      ${below('tablet')} {
        width: 200px;
      }

      ${below('mobile')} {
        position: absolute;
        top: ${isSearchPage ? 59 : 0}px;
        right: 0;
        width: 100vw;
        padding: 0 20px;
        padding-left: 10px;
        border-top: 1px solid #cfd3d6;
        border-radius: 0;
      }
    `;

    return active
      ? activeStyle
      : css`
          /* Fallback logic for no-js */
          &:focus-within {
            ${activeStyle}
          }
        `;
  }}
`;

const SearchInput = styled.input`
  width: 100%;
  padding-right: 40px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;

  color: ${colors.sherpaBlue};

  &,
  &:focus,
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    border: none;
    outline: none;
    appearance: none;
  }

  ${below('mobile')} {
    width: 100%;
    padding-left: 0;
    padding-right: 20px;

    font-size: 20px;
    font-weight: normal;
    line-height: normal;
  }
`;

const SearchForm = styled.form`
  display: flex;
  overflow: hidden;

  ${({ show }: { show: boolean }) => {
    const showStyle = css`
      width: 100%;
    `;

    return show
      ? showStyle
      : css`
          width: 0;

          &:focus-within {
            ${showStyle}
          }
        `;
  }}
`;

const SearchBar: React.FC<SearchBarProps> = ({ location, history, path }) => {
  const { t } = useI18n();
  const isSearchPage = location.pathname === `${path}/search`;
  const inputRef = useRef<HTMLInputElement>(null);
  const [lastPage, setLastPage] = useState(location.pathname);
  const [isActiveState, setIsActive] = useState(isSearchPage);
  const [termsQuery, setTermsQuery] = useQueryParam('terms', StringParam);
  const [terms, setTerms] = useState(!!termsQuery ? termsQuery : '');
  const [isInteracting, setIsInteracting] = useState(false);

  const isActive = isActiveState || isInteracting;

  useEffect(() => {
    if (termsQuery && termsQuery !== terms) {
      setTerms(termsQuery);
    }

    if (!isSearchPage && lastPage !== location.pathname) {
      setIsActive(false);
      setTerms('');
    }

    setLastPage(location.pathname);
  }, [termsQuery, location.pathname, lastPage]);

  const focusInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  const setSearchTerms = useCallback(
    ({ target }: React.SyntheticEvent) =>
      setTerms((target as HTMLInputElement).value),
    [terms]
  );

  const setActive = useCallback(
    (event?: React.SyntheticEvent) => {
      setIsActive(true);

      focusInput();

      event && event.preventDefault();
    },
    [inputRef.current]
  );

  const setFocused = useCallback(() => {
    setIsActive(true);
  }, []);

  const setUnfocused = useCallback(() => {
    if (terms.length === 0 && !isSearchPage) {
      setIsActive(false);
    }
  }, [terms, isSearchPage]);

  const onClear = useCallback(() => {
    setTerms('');
    focusInput();
  }, []);

  const onSearch = useCallback(
    (event?: React.SyntheticEvent) => {
      if (!terms) {
        focusInput();
      }

      if (terms && terms.length >= 3) {
        if (isSearchPage) {
          setTermsQuery(terms);
        } else {
          history.push(`${path}/search?terms=${terms}`);
        }
      }

      event && event.preventDefault();
    },
    [isSearchPage, terms]
  );

  const onInteraction = useCallback(() => setIsInteracting(true), []);
  const offInteraction = useCallback(() => setIsInteracting(false), []);

  return (
    <Search
      active={isActive}
      isSearchPage={isSearchPage}
      tabIndex={-1}
      onClick={focusInput}
    >
      {!isActive && (
        <Position tabIndex={-1}>
          <ClickTarget
            title={t`Go To Search Gallery`}
            to={`${path}/search`}
            onClick={setActive}
            tabIndex={-1}
            id="searchItem"
          >
            <MagnifyingGlass tabIndex={-1} />
          </ClickTarget>
        </Position>
      )}
      <SearchForm
        show={isActive}
        action={`${path}/search`}
        autoComplete="off"
        onSubmit={onSearch}
      >
        <ClickTargetButton
          type="submit"
          tabIndex={-1}
          onMouseDown={onInteraction}
          onMouseUp={offInteraction}
        >
          <MagnifyingGlass tabIndex={-1} />
        </ClickTargetButton>
        <input type="hidden" value="hidden" tabIndex={-1} />
        <SearchInput
          aria-label={t`Search Gallery`}
          ref={inputRef}
          type="search"
          name="terms"
          value={terms}
          placeholder={isActive ? t` Search...` : undefined}
          onChange={setSearchTerms}
          onFocus={setFocused}
          onBlur={setUnfocused}
        />
      </SearchForm>
      {isActive &&
        (terms.length > 0 && (
          <Position absolute={isActive}>
            <ClickTargetButton onClick={onClear}>
              <Close />
            </ClickTargetButton>
          </Position>
        ))}
    </Search>
  );
};

export default SearchBar;
