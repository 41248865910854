import * as React from 'react';
import { ButtonLink } from '../components/basic-components';
import Helmet from 'react-helmet';

import mobileErrorImage from '../images/error/error-mobile.png';
import styled from 'styled-components';
import { below, above } from '../media-query-utils';
import webErrorImage from '../images/error/error-web.png';
import { useI18n } from '../hooks';

type ErrorProps = {
  code?: string;
};

const ErrorContainer = styled.div`
  height: 100%;

  background-image: url(${webErrorImage});
  background-size: cover;
  background-position: top;

  h2 {
    color: white;
    font-weight: bold;
  }

  ${below('mobile')} {
    background-image: url(${mobileErrorImage});

    h2 {
      font-size: 30px;
      text-align: center;
      padding-left: 20vw;
      padding-right: 20vw;
    }
  }

  ${above('mobile')} {
    display: flex;
    justify-content: center;

    h2 {
      width: 443px;
      line-height: 1.07;
      font-size: 58px;
      margin-top: 0px;
    }
  }
`;

const MessageContent = styled.div`
  ${below('mobile')} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  ${above('mobile')} {
    width: 66%;
    height: 66%;
    align-self: center;
  }
`;

export const ErrorPage: React.FC<ErrorProps> = (props) => {
  const { t } = useI18n();

  return (
    <ErrorContainer>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <MessageContent>
        <h2>
          {props.children ||
            t`There appears to be a problem accessing this page.`}
        </h2>
        <ButtonLink to="/">{t`Back To Home`}</ButtonLink>
      </MessageContent>
    </ErrorContainer>
  );
};
