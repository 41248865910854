import 'isomorphic-fetch';

import { refreshConfigEndpoint } from '../sharedConfig';

export default async function refetchConfig() {
  const response = await fetch(refreshConfigEndpoint);
  const data = await response.json();

  return data;
}
