import { isExpiringSoon } from './network/AuthToken';
import { ApiConfig } from './network/ApiConfig';
import refetchConfig from './network/refetchConfig';

// Refresh auth token if expiring in this many seconds or less
const refreshLeadTimeSeconds = 60;

// These variables are passed down by the server
export const {
  __API_CONFIG__: initialApiConfig,
  __APOLLO_STATE__: apolloInitialState,
  __SENTRY_CONFIG__: sentry
} = window as any;

export let apiConfig: ApiConfig = initialApiConfig;
export const galleryApiUrl = initialApiConfig.galleryApiUrl;

export const getAuthToken = async () => {
  let authToken = apiConfig.authToken;

  if (isExpiringSoon(authToken, refreshLeadTimeSeconds)) {
    apiConfig = (await refetchConfig()).apiConfig;
    authToken = apiConfig.authToken;
  }

  return authToken;
};

// For testing
(window as any).forceExpireAuthToken = () => {
  apiConfig.authToken.expiresAt = 0;
};
