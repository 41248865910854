import { App, Screenshot } from './types';
import placeholder from './images/home/placeholder.png';
import queryString from 'query-string';

export const times = (count: number, iterator: (i: number) => void) => {
  if (!(count > 0) || typeof iterator !== 'function') {
    return [];
  }
  const ret = [];
  for (let i = 0; i < count; i += 1) {
    ret.push(iterator(i));
  }
  return ret;
};

export const getAppScreenshots = (app: App): Screenshot[] => {
  if (app.screenshots && app.screenshots.length) {
    return app.screenshots;
  }

  return app.availability.deviceTypes.map((device) => {
    return {
      ...device.displayInfo.resolution,
      uri: placeholder
    };
  });
};

export const getPreviewImage = (app: App): string => {
  if (app.previewImage && app.previewImage.uri) {
    return app.previewImage.uri;
  }

  if (app.icon && app.icon.uri) {
    return app.icon.uri;
  }

  return placeholder;
};

export const extractAccessKeyFromLocation = (location: Location): string => {
  const parsedString = queryString.parse(location ? location.search : '');
  return (parsedString.key || '').toString();
};
