import React from 'react';
import styled from 'styled-components';

import { BaseSection, NoStyleLink } from './basic-components';
import { colors } from '../style-constants';
import { below } from '../media-query-utils';
import { useI18n } from '../hooks';

import LocaleRoute from './LocaleRoute';

import RegionPicker from './RegionPicker';

const FooterBackground = styled.footer`
  width: 100%;

  background-color: ${colors.white};
  box-shadow: 0 -1px 0 0 rgba(213, 213, 213, 0.5);

  z-index: 1;
`;

const FooterWrapperWrapper = styled(BaseSection)`
  display: flex;

  padding-top: 20px;
  padding-bottom: 30px;

  justify-content: space-between;

  ${below('mobile')} {
    height: auto;
    padding: 30px 30px 40px;
    flex-direction: column;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FooterLinkRow = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;

  font-size: 14px;
  line-height: 1;
  color: ${colors.sherpaBlue};

  & > * {
    position: relative;
    top: -8px;

    margin-right: 20px;

    ${below('mobile')} {
      margin-bottom: 20px;
    }
  }

  ${below('mobile')} {
    flex-direction: column;

    font-size: 12px;
  }
`;

const Copyright = styled.div`
  white-space: nowrap;

  ${below('mobile')} {
    order: 2;

    color: ${colors.submarine};
  }
`;

const FooterLink = styled(NoStyleLink).attrs({
  external: true
})`
  ${below('mobile')} {
    order: 1;
  }
`;

const FooterNotice = styled.div`
  flex: 1;
  padding-top: 5px;

  align-content: center;

  font-size: 12px;
  color: ${colors.submarine};

  ${below('mobile')} {
    padding: 0;
  }
`;

export default () => {
  const { t } = useI18n();
  const year = new Date().getFullYear();

  return (
    <FooterBackground role="contentinfo" aria-label={t`Fitbit Gallery Footer`}>
      <FooterWrapperWrapper>
        <LocaleRoute component={RegionPicker} />
        <FooterWrapper>
          <FooterLinkRow>
            <Copyright>{t`© ${year} Fitbit, Inc. All rights reserved`}</Copyright>
            <FooterLink to="https://www.fitbit.com/legal">{t`Legal Terms`}</FooterLink>
            <FooterLink to="https://www.fitbit.com/legal/privacy-policy">
              {t`Privacy Policy`}
            </FooterLink>
            <FooterLink to="https://dev.fitbit.com/legal/app-gallery-guidelines/">
              {t`App Gallery Guidelines`}
            </FooterLink>
            <FooterLink to="https://dev.fitbit.com/legal/reporting-apps/">
              {t`Reporting Copyright & Trademark Infringement`}
            </FooterLink>
          </FooterLinkRow>
          <FooterNotice>{t`App availability subject to change.`}</FooterNotice>
        </FooterWrapper>
      </FooterWrapperWrapper>
    </FooterBackground>
  );
};
