import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { HeaderWrapper, HeaderBackground } from './Header';
import { below, above } from '../media-query-utils';
import { colors } from '../style-constants';
import { transparentize } from 'polished';

import { Portal } from 'react-portal';
import { Close } from './SearchBar';

type ModalProps = {
  title: string;
  active: boolean;
  toggle(): void;
};

const ModalActiveGlobalStyle = createGlobalStyle`
  html, body {
    overflow: hidden;
  }
`;

const MobileModalContentWrapper = styled.div`
  background-color: ${colors.white};
`;

const ModalContainer = styled.div<{ active?: boolean }>`
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 40px;

  ${below('mobile')} {
    display: ${({ active }) => (active ? 'flex' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;

    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;

    z-index: 2;
  }
`;

const MobileModalHeader = styled(HeaderBackground)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: 1px;
  color: ${colors.submarine};
  text-transform: uppercase;

  ${above('mobile')} {
    display: none;
  }
`;

const MobileModalHeaderCloser = styled(Close)`
  &&& {
    position: absolute;
    top: 0;
    right: 4px;
    width: 48px;
    height: 60px;

    background-size: 16px;
    background-position: center;
  }
`;

const MobileModalCloser = styled.div`
  flex: 1;
  height: 100%;

  background-color: ${transparentize(0.2, colors.sherpaBlue)};
`;

const MobileModal: React.FC<ModalProps> = ({
  children,
  title,
  active,
  toggle
}) => {
  return (
    <Portal>
      <ModalActiveGlobalStyle />
      <ModalContainer active={active}>
        <MobileModalHeader>
          <HeaderWrapper>
            {title}
            <MobileModalHeaderCloser onClick={toggle} />
          </HeaderWrapper>
        </MobileModalHeader>
        <MobileModalContentWrapper>{children}</MobileModalContentWrapper>
        <MobileModalCloser onClick={toggle} />
      </ModalContainer>
    </Portal>
  );
};

export default MobileModal;
